// src/components/ContactForm.jsx

import React, { useState } from 'react';
import { sendContactMessage } from '../services/api';

const ContactForm = ({ user, onClose }) => {
  const [messageData, setMessageData] = useState({
    name: user ? user.username : '',
    email: user ? user.email : '',
    message: '',
  });
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');

  const handleChange = (e) => {
    setMessageData({ ...messageData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);
    try {
      const response = await sendContactMessage(messageData);
      setMessage(response.message);
      setMessageType('success');
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      setMessage(error.message || 'Failed to send message. Please try again later.');
      setMessageType('danger');
    }
  };

  return (
    <div className="form-container">
      <h3>Contact Us</h3>
      {message && <div className={`alert alert-${messageType}`}>{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="contact-name">Name</label>
          <input
            type="text"
            className="form-control"
            id="contact-name"
            name="name"
            value={messageData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contact-email">Email</label>
          <input
            type="email"
            className="form-control"
            id="contact-email"
            name="email"
            value={messageData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contact-message">Message</label>
          <textarea
            className="form-control"
            id="contact-message"
            name="message"
            value={messageData.message}
            onChange={handleChange}
            rows="3"
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-outline-warning">Send</button>
      </form>
    </div>
  );
};

export default ContactForm;