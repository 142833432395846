// src/components/PrivacyPolicy.jsx

// src/components/PrivacyPolicy.jsx

import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h3 className="adsimple-122844946">Imprint</h3>

      <div style={{ display: 'none' }}></div>
      <div style={{ display: 'none' }}></div>
      <div style={{ display: 'none' }}></div>
      <h5
        id="eu-dispute-resolution"
        className="adsimple-122844946 fusion-responsive-typography-calculated"
      >
        EU Dispute Resolution
      </h5>
      <p className="adsimple-122844946">
        In accordance with Regulation on Online Dispute Resolution in Consumer Matters (ODR Regulation), we would like to inform you about the online dispute resolution platform (ODR platform).<br />
        Consumers have the opportunity to file complaints to the online dispute resolution platform of the European Commission at <a className="adsimple-122844946" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=EN" target="_blank" rel="follow noopener">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=EN</a>. You can find the necessary contact details above in our imprint.
      </p>
      <p className="adsimple-122844946">
        However, we would like to point out that we are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.
      </p>
      <h5
        id="liability-for-content"
        className="adsimple-122844946 fusion-responsive-typography-calculated"
      >
        Liability for the Content of this Website
      </h5>
      <p className="adsimple-122844946">
        We continuously develop the content of this website and strive to provide correct and up-to-date information. Unfortunately, we cannot accept liability for the correctness of all content on this website, especially for that provided by third parties. As a service provider, we are not obliged to monitor the information you transmit or store or to investigate circumstances that indicate illegal activity.
      </p>
      <p className="adsimple-122844946">
        Our obligations to remove information or block the use of information according to general laws due to court or official orders remain unaffected even in the case of our non-liability.
      </p>
      <p className="adsimple-122844946">
        If you notice any problematic or illegal content, we kindly ask you to contact us immediately so that we can remove the illegal content. You can find the contact details in the imprint.
      </p>
      <h5
        id="liability-for-links"
        className="adsimple-122844946 fusion-responsive-typography-calculated"
      >
        Liability for Links on this Website
      </h5>
      <p className="adsimple-122844946">
        Our website contains links to other websites for whose content we are not responsible. We are not liable for linked websites, as we had and have no knowledge of illegal activities, such illegalities have not come to our attention to date, and we would remove links immediately upon becoming aware of illegalities.
      </p>
      <p className="adsimple-122844946">
        If you notice any illegal links on our website, we kindly ask you to contact us. You can find the contact details in the imprint.
      </p>
      <h5
        id="copyright-notice"
        className="adsimple-122844946 fusion-responsive-typography-calculated"
      >
        Copyright Notice
      </h5>
      <p className="adsimple-122844946">
        All content of this website (images, photos, texts, videos) is subject to copyright. Please ask us before you distribute, duplicate or exploit the content of this website, for example by republishing it on other websites. If necessary, we will legally pursue the unauthorized use of parts of the content of our site.
      </p>
      <p className="adsimple-122844946">
        If you find any content on this website that violates copyright, we kindly ask you to contact us.
      </p>
      <h5
        id="image-rights"
        className="adsimple-122844946 fusion-responsive-typography-calculated"
      >
        Image Rights
      </h5>
      <p className="adsimple-122844946">
        The images, photos, and graphics on this website are protected by copyright.
      </p>
      <p>
        <strong>The rights to the images belong to:</strong>
        <br />Photographer Sergei Balenok
      </p>
      <div style={{ display: 'none' }}></div>
      <h5
        id="additional-online-presences"
        className="adsimple-122844946 fusion-responsive-typography-calculated"
      >
        Additional Online Presences
      </h5>
      <p>
        <strong>This imprint also applies to:</strong>
        <br />
        <a href="https://www.facebook.com/sergei.balenok/" target="_blank" rel="noopener">https://www.facebook.com/sergei.balenok/</a>
        <br />
        <a href="https://www.instagram.com/sergei_balenok/" target="_blank" rel="noopener">https://www.instagram.com/sergei_balenok/</a>
        <br />
        <a href="https://en.wikipedia.org/wiki/Sergei_Balenok" target="_blank" rel="noopener">https://en.wikipedia.org/wiki/Sergei_Balenok</a>
      </p>
      <div style={{ display: 'none' }}></div>
      <p>All texts are subject to copyright.</p>
      <p style={{ marginTop: '15px' }}>
        Source: Created with the <a href="https://www.adsimple.at/impressum-generator/" title="Imprint Generator Austria by AdSimple">Imprint Generator</a> by AdSimple
      </p>
      <h3 className="adsimple-122844946">Privacy Policy</h3>
      <h5 id="introduction-overview" className="adsimple-122844946">
        Introduction and Overview
      </h5>
      <p>
        We have written this privacy policy (version 26.07.2024-122844946) to explain to you, according to the requirements of the <a className="adsimple-122844946" href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&amp;from=EN&amp;tid=122844946#d1e2269-1-1" target="_blank" rel="noopener">General Data Protection Regulation (EU) 2016/679</a> and applicable national laws, what personal data (briefly referred to as data) we process, will process in the future, and what legitimate rights you have. The terms used should be understood in a gender-neutral manner.<br />
        <strong className="adsimple-122844946">In short:</strong> We provide you with comprehensive information about the data we process about you.
      </p>
      <p>
        Privacy policies usually sound very technical and use legal jargon. This privacy policy aims to describe the most important things to you as simply and transparently as possible. Where it promotes clarity, technical <strong className="adsimple-122844946">terms are explained in a reader-friendly way</strong>, links to further information are provided, and <strong className="adsimple-122844946">graphics</strong> are used. We inform you in clear and simple language that we only process personal data when a corresponding legal basis is present in the course of our business activities. This cannot be achieved by giving as brief, unclear, and legally technical explanations as often seen on the Internet when it comes to data protection. I hope you find the following explanations interesting and informative, and maybe there is some information that you did not know before.<br />
        If any questions remain, we kindly ask you to contact the responsible person or office listed below or in the imprint, follow the available links, and consult further information on third-party websites. Our contact details can also be found in the imprint.
      </p>
      <h5 id="scope-of-application" className="adsimple-122844946">
        Scope of Application
      </h5>
      <p>
        This privacy policy applies to all personal data processed by us in the company and to all personal data processed by companies commissioned by us (processors). Personal data means information within the meaning of Article 4 No. 1 GDPR, such as the name, email address, and postal address of a person. The processing of personal data ensures that we can offer and invoice our services and products, both online and offline. The scope of this privacy policy includes:
      </p>
      <ul className="adsimple-122844946">
        <li className="adsimple-122844946">
          all online presences (websites, online shops) that we operate
        </li>
        <li className="adsimple-122844946">
          social media presences and email communication
        </li>
        <li className="adsimple-122844946">
          mobile apps for smartphones and other devices
        </li>
      </ul>
      <p>
        <strong className="adsimple-122844946">In short:</strong> The privacy policy applies to all areas in which personal data is processed in the company via the channels mentioned. If we enter into legal relationships with you outside these channels, we will inform you separately if necessary.
      </p>
      <h5 id="legal-bases" className="adsimple-122844946">Legal Bases</h5>
      <p>
        In the following privacy policy, we provide you with transparent information about the legal principles and regulations, i.e., the legal bases of the General Data Protection Regulation, which allow us to process personal data.<br />
        Concerning EU law, we refer to the REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of 27 April 2016. You can view this GDPR online at EUR-Lex, the access to EU law, at <a className="adsimple-122844946" href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32016R0679" target="_blank" rel="noopener">https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32016R0679</a>.
      </p>
      <p>
        We process your data only if at least one of the following conditions applies:
      </p>
      <ol>
        <li className="adsimple-122844946">
          <strong className="adsimple-122844946">Consent</strong> (Article 6 Paragraph 1 lit. a GDPR): You have given us your consent to process data for a specific purpose. An example would be storing the data you enter in a contact form.
        </li>
        <li className="adsimple-122844946">
          <strong className="adsimple-122844946">Contract</strong> (Article 6 Paragraph 1 lit. b GDPR): To fulfill a contract or pre-contractual obligations with you, we process your data. For example, if we enter into a purchase contract with you, we require personal information beforehand.
        </li>
        <li className="adsimple-122844946">
          <strong className="adsimple-122844946">Legal Obligation</strong> (Article 6 Paragraph 1 lit. c GDPR): If we are subject to a legal obligation, we process your data. For example, we are legally required to keep invoices for accounting. These usually contain personal data.
        </li>
        <li className="adsimple-122844946">
          <strong className="adsimple-122844946">Legitimate Interests</strong> (Article 6 Paragraph 1 lit. f GDPR): In the case of legitimate interests that do not restrict your fundamental rights, we reserve the right to process personal data. For example, we need to process certain data to operate our website securely and efficiently. This processing is therefore a legitimate interest.
        </li>
      </ol>
      <p>
        Further conditions such as performing tasks in the public interest and exercising official authority and protecting vital interests usually do not apply to us. If such a legal basis becomes relevant, it will be indicated at the appropriate place.
      </p>
      <p>
        In addition to the EU regulation, national laws also apply:
      </p>
      <ul className="adsimple-122844946">
        <li className="adsimple-122844946">
          In <strong className="adsimple-122844946">Austria</strong>, this is the Federal Act on the Protection of Natural Persons with regard to the Processing of Personal Data (<strong className="adsimple-122844946">Data Protection Act</strong>, abbreviated <strong className="adsimple-122844946">DSG</strong>).
        </li>
        <li className="adsimple-122844946">
          In <strong className="adsimple-122844946">Germany</strong>, the <strong className="adsimple-122844946">Federal Data Protection Act</strong> applies, abbreviated <strong className="adsimple-122844946">BDSG</strong>.
        </li>
      </ul>
      <p>
        If other regional or national laws apply, we will inform you in the following sections.
      </p>
      <h5 id="contact-details" className="adsimple-122844946">Contact Details of the Data Controller</h5>
      <p>
        If you have any questions regarding data protection or the processing of personal data, you can find the contact details of the responsible person or office below:<br />
        <span className="adsimple-122844946" style={{ fontWeight: 400 }}>Ludmila Yakimova<br />
        Lüfteneggerstrasse 7/60<br />
        4020 Linz<br />
        Austria</span>
      </p>
      <p>
        Email: <a href="mailto:luda.yakimova@gmail.com">luda.yakimova@gmail.com</a>
        <br />
        Phone: <a href="tel:+4366565968400">+4366565968400</a>
        <br />
        Imprint: <a href="https://www.balenok.com/impressum/">https://www.balenok.com/impressum/</a>
      </p>
      <h5 id="storage-duration" className="adsimple-122844946">Storage Duration</h5>
      <p>
        We only store personal data for as long as it is necessary to provide our services and products. This general principle means we delete personal data when the purpose of the data processing no longer exists. In some cases, we are legally obliged to retain certain data even after the original purpose has ceased, for example, for accounting purposes.
      </p>
      <p>
        If you request the deletion of your data or revoke your consent to data processing, the data will be deleted as soon as possible, provided there is no obligation to retain it.
      </p>
      <p>
        We will inform you further below about the specific duration of each data processing where we have additional information on this.
      </p>
      <h5 id="gdpr-rights" className="adsimple-122844946">
        Rights under the General Data Protection Regulation
      </h5>
      <p>
        According to Articles 13 and 14 of the GDPR, we inform you about the following rights you are entitled to ensure fair and transparent data processing:
      </p>
      <ul className="adsimple-122844946">
        <li className="adsimple-122844946">
          You have the right to information under Article 15 GDPR. This means you have the right to request confirmation from us as to whether we process personal data concerning you. If this is the case, you have the right to request a copy of the data and the following information:
          <ul className="adsimple-122844946">
            <li className="adsimple-122844946">The purpose of processing;</li>
            <li className="adsimple-122844946">The categories of personal data processed;</li>
            <li className="adsimple-122844946">The recipients or categories of recipients to whom the data has been or will be disclosed;</li>
            <li className="adsimple-122844946">The planned storage duration or the criteria for determining this duration;</li>
            <li className="adsimple-122844946">The existence of the right to rectification, erasure, restriction of processing, and objection to processing;</li>
            <li className="adsimple-122844946">The existence of the right to lodge a complaint with a supervisory authority;</li>
            <li className="adsimple-122844946">The origin of the data if we did not collect it from you;</li>
            <li className="adsimple-122844946">The existence of automated decision-making, including profiling, and meaningful information about the logic involved and the significance and intended consequences of such processing.</li>
          </ul>
        </li>
        <li className="adsimple-122844946">
          You have the right to rectification under Article 16 GDPR, which means we must correct inaccurate data about you.
        </li>
        <li className="adsimple-122844946">
          You have the right to erasure ("right to be forgotten") under Article 17 GDPR, which means you can request the deletion of your data.
        </li>
        <li className="adsimple-122844946">
          You have the right to restriction of processing under Article 18 GDPR, which means we can only store the data but no longer use it.
        </li>
        <li className="adsimple-122844946">
          You have the right to data portability under Article 20 GDPR, which means we can provide you with your data in a common format on request.
        </li>
        <li className="adsimple-122844946">
          You have the right to object under Article 21 GDPR, which brings about a change in the processing after enforcement.
          <ul className="adsimple-122844946">
            <li className="adsimple-122844946">
              If the processing of your data is based on Article 6 Paragraph 1 lit. e (public interest, exercise of official authority) or Article 6 Paragraph 1 lit. f (legitimate interest), you can object to the processing. We will then check whether we can legally comply with this objection.
            </li>
            <li className="adsimple-122844946">
              If the data is used for direct marketing, you can object to this type of data processing at any time. We may then no longer use your data for direct marketing purposes.
            </li>
            <li className="adsimple-122844946">
              If data is used for profiling, you can object to this type of data processing at any time. We may then no longer use your data for profiling.
            </li>
          </ul>
        </li>
        <li className="adsimple-122844946">
          Under certain circumstances, you have the right not to be subject to a decision based solely on automated processing (including profiling) under Article 22 GDPR.
        </li>
        <li className="adsimple-122844946">
          You have the right to lodge a complaint under Article 77 GDPR, meaning you can lodge a complaint with a supervisory authority if you believe that the processing of your data violates the GDPR.
        </li>
      </ul>
      <p>
        <strong className="adsimple-122844946">In short:</strong> You have rights, so do not hesitate to contact the responsible person at our company!
      </p>
      <p>
        If you believe that the processing of your data violates data protection law or your data protection rights have been violated in any other way, you can complain to the supervisory authority. In Austria, this is the Data Protection Authority, whose website you can visit at <a className="adsimple-122844946" href="https://www.dsb.gv.at/?tid=122844946" target="_blank" rel="noopener">https://www.dsb.gv.at/</a>. In Germany, there is a data protection officer for each federal state. For more information, you can contact the <a className="adsimple-122844946" href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener">Federal Commissioner for Data Protection and Freedom of Information (BfDI)</a>. For our company, the following local data protection authority is responsible:
      </p>
      <h5 id="data-processing-security" className="adsimple-122844946">Security of Data Processing</h5>
      <p>
        To protect personal data, we have implemented both technical and organizational measures. Where possible, we encrypt or pseudonymize personal data. This makes it as difficult as possible for third parties to draw conclusions about personal information from our data within the scope of our possibilities.
      </p>
      <p>
        Article 25 GDPR refers to "data protection by design and by default," meaning that both software (e.g., forms) and hardware (e.g., access to the server room) must always consider security and implement appropriate measures. We will address specific measures below if necessary.
      </p>
      <h5 id="communication" className="adsimple-122844946">Communication</h5>
      <table border="1" cellPadding="15">
        <tbody>
          <tr>
            <td>
              <strong className="adsimple-122844946">Communication Summary</strong>
              <br />
              &#x1f465; Data subjects: Everyone who communicates with us by phone, email, or online form<br />
              &#x1f4d3; Processed data: e.g., phone number, name, email address, form data entered. More details can be found with the specific contact method used.<br />
              &#x1f91d; Purpose: Handling communication with customers, business partners, etc.<br />
              &#x1f4c5; Storage duration: Duration of the business transaction and legal requirements<br />
              &#x2696;&#xfe0f; Legal bases: Art. 6 Paragraph 1 lit. a GDPR (consent), Art. 6 Paragraph 1 lit. b GDPR (contract), Art. 6 Paragraph 1 lit. f GDPR (legitimate interests)
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        When you contact us by phone, email, or online form, personal data may be processed.
      </p>
      <p>
        The data will be processed for handling and processing your question and the related business transaction. The data is stored for as long as necessary or required by law.
      </p>
      <h6 className="adsimple-122844946">Data Subjects</h6>
      <p>
        All those affected by the mentioned processes are individuals who seek contact with us through the communication channels we provide.
      </p>
      <h6 className="adsimple-122844946">Phone</h6>
      <p>
        If you call us, the call data will be stored on the respective end device and by the telecommunications provider in a pseudonymized form. Additionally, data such as your name and phone number may be sent via email afterward and stored for the purpose of responding to your inquiry. The data will be deleted once the business transaction has been completed and as long as legal requirements allow.
      </p>
      <h6 className="adsimple-122844946">Email</h6>
      <p>
        If you communicate with us via email, data may be stored on the respective end device (computer, laptop, smartphone, etc.) and data may be stored on the email server. The data will be deleted once the business transaction has been completed and as long as legal requirements allow.
      </p>
      <h6 className="adsimple-122844946">Online Forms</h6>
      <p>
        If you communicate with us via an online form, data will be stored on our web server and may also be forwarded to one of our email addresses. The data will be deleted once the business transaction has been completed and as long as legal requirements allow.
      </p>
      <h6 className="adsimple-122844946">Legal Basis</h6>
      <p>
        The processing of the data is based on the following legal bases:
      </p>
      <ul className="adsimple-122844946">
        <li className="adsimple-122844946">
          Art. 6 Paragraph 1 lit. a GDPR (consent): You give us your consent to store your data and use it for purposes related to the business transaction;
        </li>
        <li className="adsimple-122844946">
          Art. 6 Paragraph 1 lit. b GDPR (contract): It is necessary to fulfill a contract with you or a processor, such as the telecommunications provider, or we need to process the data for pre-contractual activities, such as preparing an offer;
        </li>
        <li className="adsimple-122844946">
          Art. 6 Paragraph 1 lit. f GDPR (legitimate interests): We want to conduct customer inquiries and business communication in a professional setting. Certain technical tools, such as email programs, exchange servers, and mobile network operators, are necessary to operate communication efficiently.
        </li>
      </ul>
      <h5 id="cookies" className="adsimple-122844946">Cookies</h5>
      <table border="1" cellPadding="15">
        <tbody>
          <tr>
            <td>
              <strong className="adsimple-122844946">Cookies Summary</strong>
              <br />
              &#x1f465; Data subjects: Visitors to the website<br />
              &#x1f91d; Purpose: Depends on the respective cookie. More details can be found below or with the software manufacturer that sets the cookie.<br />
              &#x1f4d3; Processed data: Depends on the respective cookie. More details can be found below or with the software manufacturer that sets the cookie.<br />
              &#x1f4c5; Storage duration: Depends on the respective cookie, can vary from hours to years<br />
              &#x2696;&#xfe0f; Legal bases: Art. 6 Paragraph 1 lit. a GDPR (consent), Art. 6 Paragraph 1 lit. f GDPR (legitimate interests)
            </td>
          </tr>
        </tbody>
      </table>
      <h6 className="adsimple-122844946">What Are Cookies?</h6>
      <p>
        Our website uses HTTP cookies to store user-specific data.<br />
        Below we explain what cookies are and why they are used to better understand this privacy policy.
      </p>
      <p>
        Whenever you browse the internet, you use a browser. Known browsers include Chrome, Safari, Firefox, Internet Explorer, and Microsoft Edge. Most websites store small text files in your browser. These files are called cookies.
      </p>
      <p>
        One thing is certain: cookies are useful little helpers. Almost all websites use cookies. More precisely, they are HTTP cookies, as there are other cookies for different applications. HTTP cookies are small files that our website stores on your computer. These cookie files are automatically stored in the cookie folder, essentially the "brain" of your browser. A cookie consists of a name and a value. When defining a cookie, one or more attributes must also be specified.
      </p>
      <p>
        Cookies store specific user data about you, such as your language or personal site settings. When you visit our website again, your browser sends the "user-specific" information back to our site. Thanks to cookies, our website knows who you are and offers you the settings you are used to. In some browsers, each cookie has its own file, while in others, such as Firefox, all cookies are stored in one file.
      </p>
      <p>
        The following graphic shows a possible interaction between a web browser such as Chrome and the web server. The web browser requests a website and receives a cookie from the server, which the browser uses again when requesting another page.
      </p>
      <p>
        <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaction between Browser and Web Server" width="100%" />
      </p>
      <p>
        There are both first-party cookies and third-party cookies. First-party cookies are created directly by our site, while third-party cookies are created by partner websites (e.g., Google Analytics). Each cookie is individually evaluated, as each cookie stores different data. The expiration time of a cookie varies from a few minutes to a few years. Cookies are not software programs and do not contain viruses, Trojans, or other "malware." Cookies also cannot access information on your PC.
      </p>
      <p>Here’s an example of cookie data:</p>
      <p>
        <strong className="adsimple-122844946">Name:</strong> _ga<br />
        <strong className="adsimple-122844946">Value:</strong> GA1.2.1326744211.152122844946-9<br />
        <strong className="adsimple-122844946">Purpose:</strong> Distinguish website visitors<br />
        <strong className="adsimple-122844946">Expiration:</strong> After 2 years
      </p>
      <p>These are the minimum browser sizes supported:</p>
      <ul className="adsimple-122844946">
        <li className="adsimple-122844946">At least 4096 bytes per cookie</li>
        <li className="adsimple-122844946">At least 50 cookies per domain</li>
        <li className="adsimple-122844946">At least 3000 cookies in total</li>
      </ul>
      <h6 className="adsimple-122844946">What Types of Cookies Are There?</h6>
      <p>
        The types of cookies we use depend on the services used and are explained in more detail in the following sections of the privacy policy. Here we briefly explain the different types of HTTP cookies.
      </p>
      <p>
        There are 4 types of cookies:
      </p>
      <p>
        <strong className="adsimple-122844946">Essential Cookies</strong><br />
        These cookies are necessary to ensure the basic functions of the website. For example, they are needed when a user places a product in the shopping cart, then continues browsing other pages, and later proceeds to checkout. These cookies prevent the shopping cart from being deleted, even if the user closes the browser window.
      </p>
      <p>
        <strong className="adsimple-122844946">Functional Cookies</strong><br />
        These cookies collect information about user behavior and any error messages the user receives. In addition, these cookies are used to measure the load time and behavior of the website on different browsers.
      </p>
      <p>
        <strong className="adsimple-122844946">Targeting Cookies</strong><br />
        These cookies ensure a better user experience. For example, entered locations, font sizes, or form data are stored.
      </p>
      <p>
        <strong className="adsimple-122844946">Advertising Cookies</strong><br />
        These cookies are also called targeting cookies. They are used to deliver personalized advertising to users. This can be very practical, but also very annoying.
      </p>
      <p>
        Usually, when you visit a website for the first time, you are asked which of these cookie types you want to allow. This decision is, of course, also stored in a cookie.
      </p>
      <p>
        If you want to learn more about cookies and don’t mind technical documentation, we recommend the Request for Comments from the Internet Engineering Task Force (IETF) titled "HTTP State Management Mechanism" at <a className="adsimple-122844946" href="https://datatracker.ietf.org/doc/html/rfc6265" target="_blank" rel="noopener">https://datatracker.ietf.org/doc/html/rfc6265</a>.
      </p>
      <h6 className="adsimple-122844946">Purpose of Processing Cookies</h6>
      <p>
        The purpose of cookies ultimately depends on the respective cookie. More details can be found below or from the software manufacturer that sets the cookie.
      </p>
      <h6 className="adsimple-122844946">What Data Is Processed?</h6>
      <p>
        Cookies are small helpers for a variety of tasks. It is unfortunately impossible to generalize which data is stored in cookies, but we will inform you about the processed and stored data within the following privacy policy sections.
      </p>
      <h6 className="adsimple-122844946">Storage Duration of Cookies</h6>
      <p>
        The storage duration depends on the respective cookie and is explained below. Some cookies are deleted as soon as you leave the website, while others can remain stored on your computer for several years.
      </p>
      <p>
        You can also influence the storage duration yourself. You can delete all cookies at any time manually through your browser (see below for "Right to Object"). Furthermore, cookies that rely on your consent will be deleted once you revoke your consent, although the lawfulness of the storage until then remains unaffected.
      </p>
      <h6 className="adsimple-122844946">Right to Object – How Can I Delete Cookies?</h6>
      <p>
        Whether and how you use cookies is entirely up to you. Regardless of which service or website the cookies come from, you always have the option to delete, disable, or partially allow cookies. For example, you can block third-party cookies but allow all other cookies.
      </p>
      <p>
        If you want to determine which cookies are stored in your browser, want to change or delete cookie settings, you can find this information in your browser settings:
      </p>
      <p>
        <a className="adsimple-122844946" href="https://support.google.com/chrome/answer/95647?tid=122844946" target="_blank" rel="noopener noreferrer">Chrome: Delete, enable, and manage cookies in Chrome</a>
      </p>
      <p>
        <a className="adsimple-122844946" href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=122844946" target="_blank" rel="noopener noreferrer">Safari: Manage cookies and website data with Safari</a>
      </p>
      <p>
        <a className="adsimple-122844946" href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=122844946" target="_blank" rel="noopener noreferrer">Firefox: Clear cookies to remove data that websites have placed on your computer</a>
      </p>
      <p>
        <a className="adsimple-122844946" href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=122844946" target="_blank" rel="noopener noreferrer">Internet Explorer: Delete and manage cookies</a>
      </p>
      <p>
        <a className="adsimple-122844946" href="https://support.microsoft.com/en-gb/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=122844946" target="_blank" rel="noopener noreferrer">Microsoft Edge: Delete and manage cookies</a>
      </p>
      <p>
        If you generally do not want cookies, you can set your browser to inform you whenever a cookie is about to be set. This allows you to decide for each individual cookie whether to allow it or not. The procedure varies depending on the browser. It is best to search for the instructions in Google with the keywords "delete cookies Chrome" or "disable cookies Chrome" if you are using Chrome.
      </p>
      <h6 className="adsimple-122844946">Legal Basis</h6>
      <p>
        Since 2009, the so-called "cookie guidelines" exist. These state that storing cookies requires your <strong className="adsimple-122844946">consent</strong> (Article 6 Paragraph 1 lit. a GDPR). However, EU countries have reacted very differently to these guidelines. In Austria, the guideline was implemented in Section 165 Paragraph 3 of the Telecommunications Act (2021). In Germany, the cookie guidelines were not implemented as national law but were instead implemented in Section 15 Paragraph 3 of the Telemedia Act (TMG), which has been replaced by the Digital Services Act (DDG) since May 2024.
      </p>
      <p>
        Legitimate interests (Article 6 Paragraph 1 lit. f GDPR), which are usually of an economic nature, exist for cookies that are strictly necessary, even if no consent is present. We aim to provide visitors to our website with a pleasant user experience, and certain cookies are often strictly necessary for this purpose.
      </p>
      <p>
        Where non-essential cookies are used, this only happens with your consent. The legal basis for this is Article 6 Paragraph 1 lit. a GDPR.
      </p>
      <p>
        In the following sections, you will be informed more precisely about the use of cookies if the software used employs cookies.
      </p>
      <h5 id="web-hosting-introduction" className="adsimple-122844946">Web Hosting Introduction</h5>
      <table border="1" cellPadding="15">
        <tbody>
          <tr>
            <td>
              <strong className="adsimple-122844946">Web Hosting Summary</strong>
              <br />
              &#x1f465; Data subjects: Visitors to the website<br />
              &#x1f91d; Purpose: Professional hosting of the website and ensuring the operation<br />
              &#x1f4d3; Processed data: IP address, time of the website visit, browser used, and other data. More details can be found below or from the respective web hosting provider.<br />
              &#x1f4c5; Storage duration: Depends on the respective provider, but usually 2 weeks<br />
              &#x2696;&#xfe0f; Legal bases: Art. 6 Paragraph 1 lit. f GDPR (legitimate interests)
            </td>
          </tr>
        </tbody>
      </table>
      <h6 className="adsimple-122844946">What Is Web Hosting?</h6>
      <p>
        Whenever you visit websites today, certain information – including personal data – is automatically created and stored. This also applies to our website. These data should be processed as sparingly as possible and only with justification. By website, we mean the entirety of all webpages on a domain, i.e., everything from the homepage (home page) to the very last subpage (such as this one). By domain, we mean, for example, example.com or sampledomain.com.
      </p>
      <p>
        If you want to view a website on a computer, tablet, or smartphone, you use a program called a web browser. You probably know several web browsers by name: Google Chrome, Microsoft Edge, Mozilla Firefox, and Apple Safari. We refer to them briefly as browsers or web browsers.
      </p>
      <p>
        To display the website, the browser must connect to another computer, where the code of the website is stored: the web server. Running a web server is a complicated and time-consuming task, which is why it is usually handled by professional providers, the web hosts. These offer web hosting services, ensuring the reliable and error-free storage of website data. A whole bunch of technical terms, but please bear with us – it gets more exciting!
      </p>
      <p>
        When the browser on your computer (desktop, laptop, tablet, or smartphone) connects to the server and while data is being transferred to and from the web server, personal data may be processed. On the one hand, your computer stores data, and on the other hand, the web server must store data for a while to ensure proper operation.
      </p>
      <p>
        A picture is worth a thousand words, so the following graphic illustrates the interaction between browser, the internet, and the hosting provider.
      </p>
      <p>
        <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser and Web Server" width="100%" />
      </p>
      <h6 className="adsimple-122844946">Why Do We Process Personal Data?</h6>
      <p>
        The purposes of data processing are:
      </p>
      <ol>
        <li className="adsimple-122844946">Professional hosting of the website and ensuring operation</li>
        <li className="adsimple-122844946">Maintaining operational and IT security</li>
        <li className="adsimple-122844946">Anonymous evaluation of access behavior to improve our offer and, if necessary, to pursue legal claims</li>
      </ol>
      <h6 className="adsimple-122844946">What Data Is Processed?</h6>
      <p>
        Even as you visit our website, our web server – the computer where this website is stored – typically automatically saves data such as:
      </p>
      <ul className="adsimple-122844946">
        <li className="adsimple-122844946">The complete internet address (URL) of the accessed website</li>
        <li className="adsimple-122844946">Browser and browser version (e.g., Chrome 87)</li>
        <li className="adsimple-122844946">The operating system used (e.g., Windows 10)</li>
        <li className="adsimple-122844946">The address (URL) of the previously visited page (referrer URL) (e.g., <a className="adsimple-122844946" href="https://www.example.com/where-did-i-come-from/" target="_blank" rel="noopener">https://www.example.com/where-did-i-come-from/</a>)</li>
        <li className="adsimple-122844946">The hostname and IP address of the device accessing (e.g., COMPUTERNAME and 194.23.43.121)</li>
        <li className="adsimple-122844946">Date and time</li>
        <li className="adsimple-122844946">Stored in files called web server log files</li>
      </ul>
      <h6 className="adsimple-122844946">How Long Are Data Stored?</h6>
      <p>
        Generally, the above data is stored for two weeks and then automatically deleted. We do not pass on this data but cannot rule out that this data may be viewed by authorities in the event of illegal behavior.
      </p>
      <p>
        <strong className="adsimple-122844946">In short:</strong> Your visit is logged by our provider (the company that runs our website on specialized computers (servers)), but we do not pass on your data without your consent!
      </p>
      <h6 className="adsimple-122844946">Legal Basis</h6>
      <p>
        The lawfulness of the processing of personal data as part of web hosting results from Art. 6 Paragraph 1 lit. f GDPR (legitimate interests), as the use of professional hosting by a provider is necessary to present the company on the internet securely and user-friendly and to pursue attacks and claims that may arise from it.
      </p>
      <p>
        Typically, a contract for commissioned data processing pursuant to Art. 28 GDPR is concluded between us and the hosting provider, ensuring compliance with data protection and data security.
      </p>
      <h5 id="social-media-introduction" className="adsimple-122844946">Social Media Introduction</h5>
      <table border="1" cellPadding="15">
        <tbody>
          <tr>
            <td>
              <strong className="adsimple-122844946">Social Media Privacy Policy Summary</strong>
              <br />
              &#x1f465; Data subjects: Visitors to the website<br />
              &#x1f91d; Purpose: Representation and optimization of our service, contact with visitors, interested parties, etc., advertising<br />
              &#x1f4d3; Processed data: Data such as phone numbers, email addresses, contact information, user behavior data, information about your device, and your IP address.<br />
              More details can be found with the respective social media tool used.<br />
              &#x1f4c5; Storage duration: Depends on the social media platforms used<br />
              &#x2696;&#xfe0f; Legal bases: Art. 6 Paragraph 1 lit. a GDPR (consent), Art. 6 Paragraph 1 lit. f GDPR (legitimate interests)
            </td>
          </tr>
        </tbody>
      </table>
      <h6 className="adsimple-122844946">What Is Social Media?</h6>
      <p>
        In addition to our website, we are also active on various social media platforms. Data may be processed from users to address users interested in us through social networks. Moreover, elements of a social media platform may be embedded directly into our website. This is the case, for example, when you click on a social button on our website and are directly forwarded to our social media presence. Social media or social networks are websites and apps through which registered members can produce, exchange, and network with content, either publicly or in specific groups.
      </p>
      <h6 className="adsimple-122844946">Why Do We Use Social Media?</h6>
      <p>
        Social media platforms have been where people communicate and interact online for years. Through our social media presences, we can bring our products and services closer to interested parties. The social media elements embedded on our website help you to quickly and easily switch to our social media content.
      </p>
      <p>
        The data collected through your use of a social media platform are primarily intended for conducting web analyses. The purpose of these analyses is to develop more accurate and personal marketing and advertising strategies. Depending on your behavior on a social media platform, we can draw conclusions about your preferences, interests, and habits. As a result, we are usually provided with so-called user profiles. This allows us to display custom-fit advertisements both within and outside the social media platforms. This will usually involve cookies being set in your browser, which store data about your user behavior.
      </p>
      <p>
        We generally assume that we remain responsible for data protection, even if we use services of a social media platform. The judgement of the European Court of Justice (ECJ) has confirmed that the operators of a social media page (fan page) are jointly responsible with the social media platform for any possible privacy violations. However, social media platforms also have their own privacy policies, to which we refer you here. If you have any questions about this topic, we can help you, but you must also follow up on your inquiries directly with the social media provider.
      </p>
      <h6 className="adsimple-122844946">What Data Is Stored?</h6>
      <p>
        Which data is stored and processed exactly depends on the respective provider of the social media platform. But generally, data such as contact details, public data, behavior data, and content you share via a social media profile will be stored. Furthermore, the provider can also store information such as the device you use to access the social media platform, your IP address, and your geographical location.
      </p>
      <h6 className="adsimple-122844946">How Can I Delete My Data or Prevent Data Storage?</h6>
      <p>
        If you no longer want your data to be processed, you can delete your data at any time from the social media platform. In our case, you will also have the option to request the deletion of your data through us. In addition, social media platforms have built-in privacy options, such as privacy settings, to protect your privacy.
      </p>
      <h6 className="adsimple-122844946">Legal Basis</h6>
      <p>
        We only use social media platforms based on your consent (Art. 6 Paragraph 1 lit. a GDPR). You consent to your personal data being stored, processed, and used for marketing and communication purposes. You can, of course, revoke your consent at any time. For more details, you can contact us directly or contact the social media platform providers.
      </p>
    </div>
  );
};

export default PrivacyPolicy;



