// src/components/FullPagePopup.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ContactForm from './ContactForm';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import './FullPagePopup.css';

const FullPagePopup = ({ isVisible, handleClose, activeTab, onLogin, user }) => {
  const [currentTab, setCurrentTab] = useState(activeTab);
  const navigate = useNavigate();

  const handleClosePopup = () => {
    const event = new Event('closePopup');
    window.dispatchEvent(event);
    handleClose();
    
    // Remove #popup from the URL without adding to history
    const currentURL = window.location.href.split('#')[0];
    window.history.replaceState({}, document.title, currentURL);
  };

  const handleRegistrationSuccess = () => {
    setCurrentTab('login'); // Switch to the login form after registration
  };

  useEffect(() => {
    if (isVisible) {
      window.history.pushState({ popupOpen: true }, '', '#popup'); // Add #popup to URL when popup is opened
    }

    const handlePopState = (event) => {
      if (isVisible && !event.state?.popupOpen) {
        handleClosePopup(); // Ensure the popup is closed if user navigates back
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isVisible, navigate]);

  const renderContent = () => {
    switch (currentTab) {
      case 'terms':
        return <TermsOfService />;
      case 'privacy':
        return <PrivacyPolicy />;
      case 'login':
        return <LoginForm onClose={handleClosePopup} onLogin={onLogin} />;
      case 'register':
        return <RegisterForm onClose={handleClosePopup} onRegister={handleRegistrationSuccess} />;
      case 'contact':
        return <ContactForm onClose={handleClosePopup} user={user} />;
      default:
        return null;
    }
  };

  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains('popup-background')) {
      handleClosePopup();
    }
  };

  const handleBackgroundDoubleClick = (e) => {
    if (e.target.classList.contains('popup-background')) {
      e.preventDefault();
      e.stopPropagation();
      setTimeout(() => {
        handleClosePopup();
      }, 900); // Short delay before closing
    }
  };

  return (
    <div className={`full-page-popup ${isVisible ? 'active' : ''}`}>
      <div
        className="popup-background"
        onClick={handleBackgroundClick}
        onDoubleClick={handleBackgroundDoubleClick}
      ></div>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={handleClosePopup}>
          &times;
        </span>
        <div className="tab-content">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default FullPagePopup;

